<template>
  <AlertDialogPortal>
    <AlertDialogOverlay
      class="fixed left-0 top-0 z-[100] h-full w-full data-[state=open]:bg-black-600 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
    <AlertDialogContent
      v-bind="forwarded"
      class="fixed left-1/2 top-1/2 z-[110] w-full max-w-[480px] -translate-x-1/2 -translate-y-1/2 grid-rows-[auto_minmax(0,1fr)_auto] rounded-xl bg-white-950 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]">
      <slot />
    </AlertDialogContent>
  </AlertDialogPortal>
</template>

<script setup lang="ts">
import {
  AlertDialogContent,
  type AlertDialogContentEmits,
  AlertDialogOverlay,
  AlertDialogPortal,
  useForwardPropsEmits,
  type AlertDialogContentProps
} from 'radix-vue'

const props = defineProps<AlertDialogContentProps>()
const emits = defineEmits<AlertDialogContentEmits>()

const forwarded = useForwardPropsEmits(props, emits)
</script>
