<template>
  <AlertDialogRoot v-bind="forwarded" v-model="open">
    <AlertDialogTrigger as-child>
      <slot />
    </AlertDialogTrigger>
    <AlertDialogContent v-bind="alertDialogContentEvent">
      <AlertDialogBody>
        <slot name="body">
          <AlertDialogBodyIcon v-if="$slots['body-icon'] || icon">
            <slot name="body-icon">
              <Icon v-if="icon" class="text-6xl text-black-950" :name="icon" />
            </slot>
          </AlertDialogBodyIcon>
          <div class="flex flex-col items-start justify-center gap-2">
            <slot name="body-title">
              <AlertDialogTitle class="font-semibold">{{ title }}</AlertDialogTitle>
            </slot>
            <slot name="body-description">
              <AlertDialogDescription>{{ description }}</AlertDialogDescription>
            </slot>
          </div>
        </slot>
      </AlertDialogBody>
      <AlertDialogFooter>
        <slot name="footer-buttons" />
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialogRoot>
</template>

<script setup lang="ts">
import { camelCase, upperFirst } from 'lodash-es'
import {
  AlertDialogDescription,
  AlertDialogRoot,
  AlertDialogTrigger,
  AlertDialogTitle,
  useForwardPropsEmits,
  type AlertDialogContentEmits,
  type AlertDialogEmits
} from 'radix-vue'
import { dialogEventPropKeys } from '~/components/ui/dialog'
import type { AlertDialogProps } from './types'

const props = withDefaults(defineProps<AlertDialogProps>(), {
  closeAutoFocus: true,
  escapeKeyDown: false,
  focusOutside: true,
  interactOutside: false,
  openAutoFocus: true,
  pointerDownOutside: true
})
const emits = defineEmits<AlertDialogEmits & AlertDialogContentEmits>()
const open = defineModel<boolean>({ default: false })
const forwarded = useForwardPropsEmits(props, emits)

const alertDialogContentEvent: Record<string, (event: Event) => unknown> = {}
Object.entries(props)
  .filter(([propName, value]) => dialogEventPropKeys.includes(propName) && value === false)
  .forEach(([propName]) => {
    alertDialogContentEvent[`on${upperFirst(camelCase(propName))}`] = (event: Event) => event.preventDefault()
  })
</script>
